export default {
  filters: 'Filter',
  type: 'Occupation',
  createdBy: 'Created by',
  search: 'Search',
  clear: 'Clear',
  gender: 'Gender',
  employeeCode: 'Employee code',
  status: 'Status',
  btnCreate: 'Employee',
  employees: 'employees',
  empCode: 'Employee code',
  role: 'Role',
  description: 'Description',
  action: 'Action',
  attach: 'Attach',
  attachRole: 'Attach role',

  placeholderSearch: 'Name, email, username...',
  emptyText: 'No matching records found',

  tabInfo: 'Account Information',
  confirmation: 'Confirmation',

  userName: 'Username',
  email: 'Email',
  phoneNumber: 'Phone number',
  firstName: 'First name',
  lastName: 'Last name',
  employeeType: 'Employee occupation',
  agencies: 'Agencies',
  bookerId: 'ID Booker',
  bookerType: 'Booker type',
  scheduleCodeSE: 'Schedule code sales',
  scheduleCodeBE: 'Schedule code booker',
  placeholderScheduleCode: 'Enter schedule code',
  placeholderUsername: 'Enter username',
  placeholderEmail: 'Enter email',
  placeholderPhoneNumber: 'Enter phone number',
  placeholderFirstName: 'Enter first name',
  placeholderLastName: 'Enter last name',
  placeholderBookerId: 'Enter ID Booker',
  placeholderSelectType: 'Select occupation',
  placeholderSelectGender: 'Select gender',
  placeholder: {
    employeeCode: 'Enter the correct employee code to search',
  },
  errFetchEmpData: 'Error fetching new employee data',
  gotoEmp_1: 'Go to',
  gotoEmp_2: 'Employee List',

  information: 'Information',
  empInfo: 'Employee Information',
  customers: 'Customers',
  historyBooking: 'History Booking',
  errFetch: ' Error fetching employees data',
  errFindId_1: 'No employees found with this employees id. Check',
  errFindId_2: 'Employees List',
  errFindId_3: 'for other employees.',
  noInfo: 'No information',

  checkbox: '',
  created: 'Created',
  updated: 'Updated',

  STT: 'No.',
  Employee_First_Name: 'Employee First Name',
  Employee_Last_Name: 'Employee Last Name',
  Employee_Booker_Type: 'Booker Type',
  Employee_Type: 'Employee Occupation',
  Gender: 'Gender',
  Username: 'Username',
  Email_Address: 'Email Address',
  Phone_Number: 'Phone Number',

  moreDropdown: {
    title: 'More',
    add: 'Add Employee',
    import: 'Import Employee',
    export: 'Export Employee',
  },
  save: 'Save',
  managedAgenciesInfo: 'Information of managed agencies',
  managedAgenciesTable: {
    agencyCode: 'Agency code',
    agencyName: 'Agency name',
    emailAddress: 'Email',
    ownerPhoneNumber: 'Phone number',
    actions: 'Actions',
  },
}
