import FileSaver from 'file-saver'
import jwt from 'jsonwebtoken'
import Vue from 'vue'
import { computed } from '@vue/composition-api'

import i18n from '@/libs/i18n'
import IAmShowSpamBooking from '@/components/IAmShowSpamBooking.vue'
import IAmLinkToPaymentPassword from '@/components/IAmLinkToPaymentPassword.vue'
import IAmLinkToMFA from '@/components/IAmLinkToMFA.vue'
import router from '@/router'
import store from '@/store'

import jwtDefaultConfig from './jwtDefaultConfig'

// Maximum call refresh token
const maxRefreshAttempts = 3

// Check re-call refresh token
let refreshAttempts = 0

// For Refreshing Token
let isAlreadyFetchingAccessToken = false
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  axiosInsUser = null

  // storage
  storage = localStorage

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig, axiosInsUser) {
    this.axiosIns = axiosIns

    this.axiosInsUser = axiosInsUser // || axiosIns

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    this.urlEndpointIgnoreTokenList = [
      this.jwtConfig.loginEndpoint,
      this.jwtConfig.refreshEndpoint,
      ...jwtOverrideConfig?.urlEndpointIgnoreTokenList ?? [],
    ]
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        config.timeout = 120000
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken && axiosInsUser && !this.urlEndpointIgnoreTokenList.includes(config.url)) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          if (this.jwtConfig?.adUsername) {
            const username = JSON.parse(this.storage.getItem('userData')).username
            config.headers['ad-username'] = `${username}`
          }
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        const originalRequest = config
        const refreshToken = this.getRefreshToken()

        if (response && response.status === 401 && refreshToken) {
          const refreshTokenDecoded = jwt.decode(refreshToken)
          const refreshExp = refreshTokenDecoded.exp - 30
          const now = Math.floor(new Date().getTime() / 1000)
          if (refreshExp < now || (response.status === 401 && response.data.name === 'UnauthorizedError')) {
            this.gotoLogin()
            return Promise.reject(response.data.name)
          }
          if (!isAlreadyFetchingAccessToken) {
            const accessToken = this.getToken()
            if (config?.headers?.Authorization !== `${this.jwtConfig.tokenType} ${accessToken}`) {
              setTimeout(() => {
                this.onAccessTokenFetched(accessToken)
              }, 50)
            } else if (refreshAttempts > maxRefreshAttempts) {
              this.gotoLogin()
              return null
            } else {
              refreshAttempts += 1
              isAlreadyFetchingAccessToken = true
              this.refreshToken()
                .then(r => {
                  this.setToken(r.data.accessToken)
                  this.setRefreshToken(r.data.refreshToken)
                  this.setTokenExpiresIn(r.data.expiresIn)
                  this.setRefreshTokenExpiresIn(r.data.refreshTokenExpiresIn)
                  isAlreadyFetchingAccessToken = false
                  refreshAttempts = 0
                  this.onAccessTokenFetched(r.data.accessToken)
                })
                .catch(() => {
                  this.gotoLogin()
                })
            }
          }

          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }

        if (!refreshToken && !config.url.includes('login') && !router?.currentRoute?.fullPath?.includes('login')) {
          this.gotoLogin()
        }

        const notShowError = {
          keyword: ['flights', 'book', 'search'],
          apiUrl: ['/flights/modify/add-flights', '/terminals/', 'currency-conversion', '/membership_card', 'public/agency-config'],
          status: [401],
          baseUrl: [''],
        }

        const showError = {
          baseUrl: ['cms'],
        }

        function checkNotShowError(error, checkBase = false) {
          if (notShowError?.apiUrl?.find(item => error?.config?.url.includes(item))) {
            return false
          }
          if (notShowError?.status?.find(item => Number(error?.response?.status) === Number(item))) return false
          if (checkBase && !showError?.baseUrl?.some(item => error?.config?.baseURL.includes(item))) return false
          return true
        }

        const errorMessageList = computed(() => store.getters['globalConfig/getErrorMessage'])

        if (response && checkNotShowError(error)) {
          // hide payment password in request
          const requestToCopy = error?.config?.data ? JSON.parse(error?.config?.data) : ''
          delete requestToCopy.paymentPassword

          if (typeof error?.response?.data === 'object' && error?.response?.data?.message) {
            const textShow = errorMessageList.value?.length
              ? errorMessageList.value.find(i => i.key === error.response.data.message) || errorMessageList.value.find(err => JSON.stringify(error.response.data.message).includes(err.key))
              : null
            const langValue = localStorage.getItem('Locale') === 'en' ? 'value' : `${localStorage.getItem('Locale')}Value`

            const errorCodeShowCustomModal = [
              { code: 'REQUIRE_ENABLE_MFA_FOR_PAYMENT', modal: IAmLinkToMFA },
              { code: 'WARNING_SPAM_BOOKING', modal: IAmShowSpamBooking, props: { codeList: error?.response?.data?.data || [] } },
              { code: 'PAYMENT_PASSWORD_IS_REQUIRED', modal: IAmLinkToPaymentPassword },
            ]

            const errorShowCustomModal = errorCodeShowCustomModal.find(e => e.code === error.response.data.message)

            Vue.swal({
              title: 'Có lỗi xảy ra!',
              html: `
                  <div class="">
                    ${textShow ? textShow?.[langValue] : `${i18n.t('pleaseCheckError')}: ${typeof error.response.data.message === 'object' ? JSON.stringify(error.response.data.message) : error.response.data.message}`}
                    ${errorShowCustomModal ? '<div id="custom-modal"></div>' : ''}
                    ${error.response.headers['trace-id'] ? `<small>trace_id: ${error.response.headers['trace-id']}` : ''}
                  </div>`,
              icon: 'error',
              didOpen: () => {
                if (errorShowCustomModal) {
                  new Vue({
                    render: h => h(errorShowCustomModal?.modal, { ...errorShowCustomModal?.props || {} }),
                    i18n,
                    router,
                  }).$mount('#custom-modal')
                }
              },
              showCancelButton: true,
              showConfirmButton: true,
              showDenyButton: true,
              confirmButtonText: 'Copy lỗi',
              cancelButtonText: 'Đóng',
              denyButtonText: 'Tải lại trang',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-outline-danger ml-1',
                denyButton: 'btn btn-outline-info text-underline ml-1',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  navigator.clipboard.writeText(
                    `[${error?.response?.status}] ${JSON.stringify(error?.response?.data)} | trace_id: ${error.response.headers['trace-id'] ?? null} | ${error?.request?.responseURL}: ${JSON.stringify(requestToCopy)}`,
                  )
                  Vue.swal({
                    icon: 'success',
                    title: 'Đã copy!',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
                else if (result?.isDenied) {
                  window.location.reload()
                }
              })
          } else {
            Vue.swal({
              title: `[${error?.response?.status}] ${error?.response?.data?.name}`,
              text:
                typeof error?.response?.data?.message === 'object'
                  ? JSON.stringify(error?.response?.data?.message)
                  : error?.response?.data?.message,
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Copy lỗi',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                navigator.clipboard.writeText(
                  `[${error?.response?.status}] ${JSON.stringify(
                    error?.response?.data,
                  )} | ${error?.request?.responseURL}: ${JSON.stringify(requestToCopy)}`,
                )
                Vue.swal({
                  icon: 'success',
                  title: 'Đã copy!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
          }
        }

        if ((!response || response?.status === 504) && checkNotShowError(error, true)) {
          Vue.swal({
            title: `${error?.response?.status ? `[${error?.response?.status}]` : ''} KẾT NỐI KHÔNG ỔN ĐỊNH, NẾU THAO TÁC BOOKING VUI LÒNG KIỂM TRA TRƯỚC KHI THỬ LẠI`,
            icon: 'error',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: 'Tải lại trang',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-flat-dark',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                window.location.reload()
              }
            })
        }
        return Promise.reject(error)
      },
    )
  }

  setStorage(storage) {
    this.storage = storage
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    if (localStorage.getItem(this.jwtConfig.storageTokenKeyName)) {
      this.setStorage(localStorage)
      return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }
    if (sessionStorage.getItem(this.jwtConfig.storageTokenKeyName)) {
      this.setStorage(sessionStorage)
      return sessionStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }
    return null
  }

  getRefreshToken() {
    if (localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)) {
      this.setStorage(localStorage)
      return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }
    if (sessionStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)) {
      this.setStorage(sessionStorage)
      return sessionStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }
    return null
  }

  setToken(value) {
    this.storage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setTokenExpiresIn(value) {
    this.storage.setItem(this.jwtConfig.storageTokenExpiresInKeyName, value)
  }

  setRefreshToken(value) {
    this.storage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  setRefreshTokenExpiresIn(value) {
    this.storage.setItem(
      this.jwtConfig.storageRefreshTokenExpiresInKeyName,
      value,
    )
  }

  login(...args) {
    return this.axiosInsUser.post(this.jwtConfig.loginEndpoint, ...args)
  }

  mfaLogin(...args) {
    return this.axiosInsUser.post(this.jwtConfig.mfaLoginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosInsUser.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosInsUser.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  gotoLogin() {
    const isVisible = Vue.swal.isVisible()
    if (!isVisible) {
      Vue.swal({
        title: i18n.t('loginSessionExpired'),
        text: i18n.t('loginSessionExpiredContent'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: i18n.t('agree'),
        customClass: {
          confirmButton: 'btn btn-warning',
        },
        allowOutsideClick: false,
        buttonsStyling: false,
      })
        .then(result => {
          if (result) {
            // eslint-disable-next-line no-underscore-dangle
            let callbackUrl = router.history._startLocation
            if (callbackUrl === '/') callbackUrl = ''

            this.clearStorage()
            router.push({ name: 'auth-login', query: callbackUrl ? { callbackUrl } : {} })
          }
        })
    }
  }

  logout() {
    return this.axiosInsUser.post(this.jwtConfig.logoutEndpoint)
  }

  // eslint-disable-next-line class-methods-use-this
  clearStorage() {
    localStorage.clear()
    sessionStorage.clear()
  }

  get(url, params) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(url, { params })
        .then(r => {
          resolve(r)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  pdf(url) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(url, {
          responseType: 'blob',
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  excel(url, params, fileName) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(url, params, {
          responseType: 'blob',
        })
        .then(res => {
          FileSaver.saveAs(res.data, fileName)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  post(url, body, params) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(url, body, { params: { ...params } })
        .then(r => {
          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  upload(url, data) {
    return new Promise((resolve, reject) => {
      this.axiosIns.defaults.timeout = -1
      this.axiosIns
        .post(url, data, {
          headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then(r => {
          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  // updateVideo(url, params) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosIns
  //       .post(url, params, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       })
  //       .then(r => {
  //         resolve(r.data)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // }

  put(url, params) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(url, params)
        .then(r => {
          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  patch(url, params) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .patch(url, params)
        .then(r => {
          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  delete(url, data = {}, headers = {}) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(url, { headers, data })
        .then(r => {
          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
