export default {
  firstName: 'Tên đệm & Tên',
  lastName: 'Họ',
  birthday: 'Ngày sinh',
  email: 'Email',
  phone: 'Số điện thoại',
  gender: 'Giới tính',
  companyName: 'Tên công ty',
  addressCompany: 'Địa chỉ công ty',
  idNumber: 'Số căn cước công dân',
  issueDate: 'Ngày cấp',
  expDate: 'Ngày hết hạn',
  placeOfIssue: 'Nơi cấp',
  enter: 'Điền vào',
  edit: 'Chỉnh sửa',
  save: 'Lưu',
  back: 'Quay lại',
  employeeConfig: {
    title: 'Cài đặt mặc định',
    arrivalCode: 'Sân bay đến mặc định',
    departureCode: 'Sân bay đi mặc định',
    isPriceBargainFinder: 'Tính năng tính giá rẻ nhất (VN)',
    showAddonForSms: 'Hiện thông tin dịch vụ mua thêm trên SMS',
    showArrivalTimeForSms: 'Hiện thời gian hạ cánh trên SMS',
    isShowAircraft: 'Hiển thị loại máy bay',
    isShowBookingClass: 'Hiển thị hạng vé',
    isShowFee: 'Hiển thị phí xuất vé',
    isShowFeeService: 'Hiển thị phí dịch vụ',
    isShowPromotion: 'Hiển thị chiết khấu',
    showPriceOptions: 'Hiển thị loại giá',
    sortType: 'Sắp xếp theo',
    errorUpdateConfig: 'Lỗi chỉnh sửa cài đặt mặc định',
    errorUpdateConfigDuplicateCode: 'Điểm đi và điểm đến không được trùng',
    successUpdateConfig: 'Chỉnh sửa cài đặt mặc định thành công',
    titleAirport: 'Cài đặt sân bay mặc định',
    titleResultSearch: 'Cài đặt hiển thị kết quả tìm kiếm',
    titleFeature: 'Cài đặt tính năng',
  },
  paymentPassword: {
    title: 'Mật khẩu thanh toán',
    password: 'Mật khẩu',
    updateError: 'Lỗi cập nhật mật khẩu thanh toán.',
    updateSuccess: 'Cập nhật mật khẩu thanh toán thành công.',
    reset: 'Cài lại mật khẩu thanh toán',
    placeholder: 'Vui lòng nhập mật khẩu thanh toán',
    rePlaceholder: 'Nhập lại mật khẩu thanh toán',
    errorInput: 'Mật khẩu thanh toán sai định dạng, vui lòng nhập lại!',
    differentRePassword: 'Mật khẩu thanh toán không giống nhau',
    submitInput: 'Thanh toán',
    resetConfirm: 'Bạn chắc chắn muốn cài đặt lại mật khẩu thanh toán về mật khẩu mặc định ?',
    changePassword: 'Đổi mật khẩu thanh toán',
    oldPassword: 'Mật khẩu thanh toán cũ',
    newPassword: 'Mật khẩu thanh toán mới',
    reNewPasswordPlaceholder: 'Nhập lại mật khẩu thanh toán mới',
    confirmTurnOff: 'Xác nhận tắt mật khẩu thanh toán',
  },
  mfa: {
    title: 'Xác thực 2 bước (MFA)',

    create: {
      title: 'Tạo MFA',
      otpVerify: 'OTP',
      nameVerify: 'Tên MFA',
      confirmMfa: 'Xác thực',
      type: 'Phương thức',
      instruction: {
        title: 'Hướng dẫn thiết lập',
        step1Title: '1. Tải ứng dụng xác thực xuống',
        step1Content: 'Nếu chưa cài đặt ứng dụng xác thực nào, bạn nên tải Google Authenticator xuống.',
        step2Title: '2. Quét mã QR này hoặc sao chép khóa',
        step2Content: 'Quét mã QR này trong ứng dụng xác thực hoặc sao chép khóa rồi dán vào ứng dụng xác thực.',
        step3Title: '3. Sao chép và nhập mã gồm 6 chữ số',
        step3Content: 'Sau khi bạn quét mã QR hoặc nhập khóa, ứng dụng xác thực sẽ tạo mã gồm 6 chữ số. Hãy sao chép rồi quay lại đây để nhập mã.',
      },
      createMfaError: 'Lỗi tạo MFA',
      createMfaSuccess: 'Tạo MFA thành công',
      verifyMfaError: 'Lỗi xác thực MFA',
    },

    columns: {
      no: 'STT',
      name: 'Tên',
      type: 'Phương thức',
      createdAt: 'Ngày tạo',
      action: 'Thao tác',
    },
    confirmDelete: 'Bạn có chắc muốn xóa MFA này?',
    noData: 'Bạn chưa tạo MFA. Vui lòng tạo MFA.',
    enableMfaLogin: 'Bật xác thực 2 bước khi đăng nhập',
    disableMfaLoginSuccess: 'Đã tắt xác thực 2 bước khi đăng nhập',
    enableMfaLoginSuccess: 'Đã bật xác thực 2 bước khi đăng nhập',
    deleteMfaSuccess: 'Xoá xác thực 2 bước thành công',
    inputOtpMfa: 'Vui lòng nhập OTP xác thực 2 bước',
    disableMfaLoginFirst: 'Vui lòng tắt xác thực 2 bước khi đăng nhập trước khi xoá MFA',
    InvalidOtpMfa: 'Sai OTP. Vui lòng nhập lại OTP!',
    fetchMfaError: 'Lỗi tải dữ liệu MFA',
    linkToMfa: 'Click vào đây để cài đặt xác thực 2 bước (MFA).',
  },
}
