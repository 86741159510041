export default {
  pageTitle: 'Mẫu Vé Điện Tử',
  sectionsTitle: {
    config: 'Cài đặt mẫu vé điện tử',
    template: 'Mẫu vé điện tử',
  },
  showHideConfig: 'Hiển thị/ ẩn cài đặt vé',
  agencyInfo: 'Thông tin AG',
  showBackground: 'Hình nền',
  price: 'Giá tiền',
  holdTime: 'Thời gian giữ',
  ticketStatus: 'Trạng thái thanh toán',
  bookingClass: 'Hạng vé',
  useEditor: 'Sử dụng Editor',
  paymentInfo: 'QR thanh toán',
  qrBookingInfo: 'QR thông tin vé',
  priceNetFare: 'Giá + Thuế và phí',
  priceDetail: 'Chi tiết giá',
  showAncillaries: 'Dịch vụ bổ sung',
  showBackgroundColor: 'Hiển thị màu nền',
  bankAccount: 'Tài khoản ngân hàng',
  phBankAccount: 'Chọn tài khoản ngân hàng',
  language: 'Ngôn ngữ mặt vé',
  color: 'Màu chủ đề',
  colors: {
    yellow: 'Vàng',
    red: 'Đỏ',
    green: 'Xanh lục',
    blue: 'Xanh lam',
    lightBlue: 'Xanh lam nhạt',
    black: 'Đen',
    pink: 'Hồng',
    indigo: 'Chàm',
    purple: 'Tím',
    orange: 'Cam',
    teal: 'Xanh mòng két',
    gray: 'Xám',
  },
  eTicket: 'Vé điện tử',
  prepaymentNote: 'Quý khách vui lòng thanh toán trước: 18h30 ngày 14/06/2023',
  payBefore: 'Quý khách vui lòng thanh toán trước',
  clickToChangeStatus: 'Click để chỉnh trạng thái của vé thành',
  statusHold: 'GIỮ CHỖ',
  statusCancel: 'ĐÃ HUỶ',
  statusPaid: 'ĐÃ THANH TOÁN',
  flightsInfo: {
    bookingID: 'Mã đặt chỗ',
    trip1: '(Thứ Tư) 21/06/2023 Hà Nội - Hồ Chí Minh',
    trip2: '(Thứ Hai) 26/06/2023 Hồ Chí Minh - Hà Nội',
    departTime: 'Cất cánh',
    arrivalTime: 'Hạ cánh',
    terminal: 'Ga số',
    codeshareWith: 'Được vận chuyển bởi',
    aircraft: 'Máy bay',
    distance: 'Khoảng cách (Dặm)',
    duration: 'Thời gian bay',
    handBaggage: 'Hành lý xách tay',
    freeBaggage: 'Hành lý ký gửi',

    flight: 'Chuyến bay',
    transitIn: 'Quá cảnh tại',
    transitTime: 'Thời gian chờ',
    airline: 'Hãng vận chuyển',
  },
  na: 'Không áp dụng',
  pnrCodes: {
    // https://support.travelport.com/webhelp/uapi/Content/Air/Shared_Air_Topics/PNR_Status_Codes.htm
    HK: 'Xác nhận giữ',
  },
  paxTable: {
    no: 'STT',
    passenger: 'Hành khách',
    additionalServices: 'Dịch vụ bổ sung',
    pax1: 'TRAN VIET DUNG',
    pax2: 'NGUYEN MINH DUC',
    pax3: 'TRAN LE PHUONG',
    bagsTicket1: 'SGN - HAN: 1 kiện 23KG',
    bagsTicket2: 'SGN - HAN: 1 kiện 23KG',
    bagsTicket3: '',
  },
  ticketPrice: 'Giá vé',
  ticketAncillaries: 'Dịch vụ bổ sung',
  priceTable: {
    passenger: 'Hành khách',
    fare: 'Giá',
    tax: 'Thuế và phí',
    total: 'Tổng',
    totalOfPrice: 'Tổng tiền',
  },
  note: {
    title: 'Quý khách xin lưu ý',
    content1:
      'Quý khách vui lòng sắp xếp thời gian có mặt tại sân bay đúng giờ (trước 90 phút cho chuyến bay nội địa và 180 phút cho chuyến bay quốc tế) để đảm bảo hoàn thành các thủ tục cho chuyến bay. Hãng hàng không sẽ không chấp nhận vận chuyển hành khách sau thời điểm đóng quầy.',
    content2:
      'Giấy tờ tùy thân: CMND/CCCD, Hộ chiếu (Passport), Giấy phép lái xe, Thẻ Đảng,...',
    content3:
      'Từ 14 tuổi chưa có CMND/CCCD: Giấy xác nhận nhân thân (có giá trị trong 30 ngày).',
    content4:
      'Dưới 14 tuổi: Giấy khai sinh bản chính hoặc bản sao y trích lục.',
    content5:
      'Trẻ sơ sinh: Giấy khai sinh/Giấy chứng sinh (đối với trường hợp dưới 1 tháng tuổi chưa có giấy khai sinh).',
    content6:
      'Xin cảm ơn và chúc quý khách có một chuyến bay tốt đẹp!',
  },
  saveTicketConfigSuccess: 'Lưu cài đặt vé thành công.',
  saveTicketConfigErr: 'Lưu cài đặt vé không thành công.',
  exportTicketSuccess: 'Xuất PDF mặt vé thành công.',
  exportTicketgErr: 'Xuất PDF mặt vé không thành công.',
  pack: 'kiện',
  oversized: 'quá khổ',
  ticketAlertWarning: {
    1: 'Đây là mặt vé trao đổi thông tin giữa đại lý và khách hàng.',
    2: 'Thông tin không hoàn toàn chính xác 100% với booking trên hãng. Đại lý vui lòng kiểm tra trước khi gửi khách hàng.',
  },
  clickChangeBookingClassBadge: 'Click để thay đổi hạng vé',
  errorBookingClassBadge: 'Thông tin hạng vé có thể không đúng, vui lòng điều chỉnh lại.',
  eticketTemplate: {
    modern: 'Hiện đại',
    basic: 'Cơ bản',
    title: 'Mẫu mặt vé',
  },
}
